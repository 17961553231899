import styled from "styled-components";
import { themeGet } from "styled-system";

import Box from "../Box";
import TextF2 from "../TextF2";
import TextF4 from "../TextF4";
import TextF8 from "../TextF8";

const BackgroundBox = styled(Box)`
  background-color: #4844a3;
  min-height: calc(100vh - 159px);
`;

const Error = styled.div`
  color: #ff7351;
`;

const Success = styled.div`
  @keyframes flash {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  opacity: 0;
  animation: flash 3s;
  color: #32c2a2;
  display: inline-block;
`;

const SubmitButton = styled.button`
  font-size: 1rem;
  outline: none !important;
  font-family: ${themeGet("fonts.TTNormsProBold")};
  color: ${props =>
    props.disabled ? "rgba(255,255,255,0.5)" : "rgba(255,255,255,1)"};
  max-width: 280px;
  padding: 1rem;
  line-height: 2rem;
  border-radius: 2rem;
  background: #32c2a2;
  border: none;
  width: ${props => (props.disabled ? "80%" : "100%")};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
  transition: all 0.2s ease;
  display: block;
  box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.04);
  :hover {
    cursor: pointer;
  }
  margin: 0 auto;
`;

const StyledF2 = styled(TextF2)`
  text-align: center;
  color: white;
`;

const StyledF4 = styled(TextF4)`
  text-align: center;
  color: white;
  margin: 0;
`;

const StyledF8 = styled(TextF8)`
  color: white;
`;

const FormContainer = styled.div`
  padding-bottom: 60px;
`;

export {
  BackgroundBox,
  Error,
  Success,
  SubmitButton,
  StyledF2,
  StyledF4,
  StyledF8,
  FormContainer
};
